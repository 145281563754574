<template>
  <SwitchChildRouteAtomStyled>
    <SwitchStyled v-for="childRoute in childRoutes" :key="childRoute.name">
      <div class="disabled" v-if="childRoute.disabled">
        {{ childRoute.label }}
      </div>
      <router-link v-else class="switch" :to="{ name: childRoute.name, query: childRoute.query }">
        {{ childRoute.label }}
      </router-link>
    </SwitchStyled>
  </SwitchChildRouteAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const SwitchChildRouteAtomStyled = styled('div')`
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: min-content;
  grid-auto-columns: min-content;
  grid-gap: 1rem;
  align-items: center;
`
const SwitchStyled = styled('div')`
  > a,
  > .disabled {
    user-select: none;
    display: block;
    font-size: 0.875rem;
    padding: 0.5rem 0.25rem;
    border-bottom: 2px solid ${({ theme }) => (theme.isDark ? theme.colors.atomic.osloGrey : theme.colors.white)};
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.muted} !important;
    &.switch,
    &.router-link-active {
      color: ${({ theme }) => theme.colors.muted} !important;
    }
    &.router-link-active {
      color: ${({ theme }) => theme.colors.primary} !important;
      border-bottom: 2px solid ${({ theme }) => theme.colors.atomic.primary};
    }
  }
  > .disabled {
    border-bottom: 2px solid transparent;
  }
`
export default {
  props: {
    childRoutes: {
      type: Array,
      required: true,
    },
  },
  components: {
    SwitchChildRouteAtomStyled,
    SwitchStyled,
  },
}
</script>
