<template>
  <AssetNameStyled>
    <IconStyled>
      <TalpaIcon v-if="iconName" class="asset-icon" :scope="'AssetTypes'" :name="iconName" />
    </IconStyled>
    <TextWrapper>
      {{ assetName }}
    </TextWrapper>
  </AssetNameStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'

const AssetNameStyled = styled('div')`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'icon name';
  align-items: center;
  @media (max-width: 1024px) {
  }
`
const IconStyled = styled('div')`
  display: flex;
  grid-area: icon;
  align-items: center;
  .asset-icon {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    svg.icon {
      width: max-content;
      height: max-content;
      padding: 10px;
    }
  }
`
const TextWrapper = styled('div')`
  text-transform: uppercase;
  margin-left: 8px;
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
  grid-area: name;
`

export default {
  components: {
    AssetNameStyled,
    IconStyled,
    TextWrapper,
    TalpaIcon,
  },
  props: {
    typeName: {
      type: String,
      default: '',
    },
    assetName: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      return this.typeName.split(' ').join('')
    },
  },
}
</script>
