var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetNameStyled', [_c('IconStyled', [_vm.iconName ? _c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _vm._e()], 1), _c('TextWrapper', [_vm._v(" " + _vm._s(_vm.assetName) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }