var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SwitchChildRouteAtomStyled', _vm._l(_vm.childRoutes, function (childRoute) {
    return _c('SwitchStyled', {
      key: childRoute.name
    }, [childRoute.disabled ? _c('div', {
      staticClass: "disabled"
    }, [_vm._v(" " + _vm._s(childRoute.label) + " ")]) : _c('router-link', {
      staticClass: "switch",
      attrs: {
        "to": {
          name: childRoute.name,
          query: childRoute.query
        }
      }
    }, [_vm._v(" " + _vm._s(childRoute.label) + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }