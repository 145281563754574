var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthHeaderWrapperStyled', [_vm.asset ? _c('AssetHealthHeaderStyled', [_c('div', {
    staticClass: "row1"
  }, [_c('AssetNameMolecule', {
    staticClass: "name",
    attrs: {
      "typeName": _vm.asset.type.name,
      "assetName": _vm.asset.name
    }
  }), _vm.isLoading ? _c('LoadingDots', {
    staticClass: "loader"
  }) : _c('SeverityMolecule', {
    staticClass: "severity",
    attrs: {
      "severities": _vm.asset.assetSeverities
    }
  }), _vm.assetIssues ? _c('InsightsCountMolecule', {
    staticClass: "insights",
    attrs: {
      "value": _vm.assetIssues.length
    }
  }) : _vm._e(), !_vm.isSmall ? _c('SwitchChildRouteAtom', {
    staticClass: "routes",
    attrs: {
      "childRoutes": _vm.childRoutes
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "row2"
  }, [_c('AssetDetailsMolecule', {
    staticClass: "details",
    attrs: {
      "asset": _vm.assetDataWithIntegratedDate,
      "isIntegratedDateLoading": _vm.isIntegrationDateLoading
    }
  })], 1), _vm.isSmall ? _c('div', {
    staticClass: "row3"
  }, [_c('SwitchChildRouteAtom', {
    staticClass: "routes",
    attrs: {
      "childRoutes": _vm.childRoutes
    }
  })], 1) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }